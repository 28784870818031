<template>
  <div
    class="
      login
      bg-white
      border border-eee
      relative
      flex
      rounded
      overflow-hidden
    "
  >
    <span class="absolute top-20 left-20 z-10">
      <router-link to="/auth/login" class="text-white text-sm"> 返回 </router-link>
    </span>
    <div class="absolute top-0 left-0 flag border-primary"></div>
    <div class="divider bg-eee"></div>
    <div class="flex flex-col justify-center login-form px-40">
      <div class="text-28 font-bold text-111 text-center mb-60">找回密码</div>
      <a-form-model ref="container" :model="model" :rules="rules">
        <a-form-model-item prop="email">
          <gf-input
            :maxLength="100"
            size="large"
            v-model="model.email"
            placeholder="请输入邮箱/手机"
          ></gf-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password
            :maxLength="20"
            size="large"
            type="password"
            v-model="model.password"
            placeholder="请设置登录密码（不少于8位数）"
          ></a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input
            :maxLength="6"
            size="large"
            v-model="model.code"
            placeholder="请输入验证码"
          >
            <count-down
              slot="suffix"
              text="发送验证码"
              :count="60"
              :send="sendResetCode"
            >
            </count-down>
          </a-input>
        </a-form-model-item>
      </a-form-model>

      <a-button @click="reset" class="w-full mt-40" type="primary" size="large"
        >修改密码</a-button
      >
    </div>
    <div class="login-desc flex justify-center items-center">
      <img src="@/assets/images/login-inset.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { reset, sendResetCode } from "@/api";
import { sendResetPhoneCode } from "@/api/user";
import { getUserInfo } from "@/api/user";
import {
  validateEmail,
  validateTel,
  validatePassword,
} from "@/utils/validator";

export default {
  name: "login",
  components: {},
  data() {
    return {
      model: {},
      type: "all",
    };
  },
  methods: {
    reset() {
      this.type = "all";
      this.$nextTick(() => {
        this.$refs.container.validate((valid) => {
          if (valid) {
            const { companyName, email, password, code } = this.model;
            const temp = {
              companyName,
              password,
              code,
            };
            if (!this.isEmail) {
              temp.phone = email;
            } else {
              temp.email = email;
            }
            reset(temp)
              .then(({ code, msg }) => {
                this.$message.success("修改成功");
                this.$router.push("/login");
              })
              .catch(({ msg }) => {
                this.$message.error(msg);
              });
          }
        });
      });
    },
    async sendResetCode() {
      this.type = "";
      const promise = new Promise((resolve) => {
        this.$nextTick(() => {
          this.$refs.container.validate((valid) => {
            resolve(valid);
          });
        });
      });

      const result = await promise;
      if (!result) return;

      if (this.isEmail) {
        return sendResetCode({ email: this.model.email });
      } else {
        return sendResetPhoneCode({ phone: this.model.email });
      }
    },
  },
  computed: {
    isEmail() {
      return /[a-zA-Z@.]/.test(this.model.email);
    },
    rules() {
      return {
        email: [
          {
            required: true,
            message: "请输入邮箱/手机",
            trigger: "blur",
          },
          {
            validator: this.isEmail ? validateEmail : validateTel,
            message: "请输入正确的邮箱或手机格式",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: this.type === "all",
            message: "请输入登录密码",
            trigger: "blur",
          },
          {
            min: this.type === "all" ? 8 : 0,
            max: this.type === "all" ? 16 : 255,
            message: "登录密码长度8-16位",
            trigger: "blur",
          },
          {
            validator: validatePassword,
            message: "密码应包含数字、字母、字符中的两种或两种以上",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: this.type === "all",
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.login {
  width: 900px;
  height: 560px;
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.login-form {
  width: 400px;
}
.login-desc {
  width: 500px;
}
.flag {
  width: 100px;
  height: 100px;
  border-width: 50px;
  border-width: 50px;
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.divider {
  position: absolute;
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 360px;
}
</style>